<template>
  <div>
    <div style="display: flex">
      <Button
        @click="seeDemandStatus= true;"
        type="primary"
        >查看案主问题及需求</Button
      >
      <h2 style="margin-bottom: 20px; flex: 1; padding-left: calc(50% - 190px)">
        需求评估
      </h2>
    </div>
    <Form :model="archivesData.archiveAssessNeedVo" :label-colon="true" :disabled="true">
        <FormItem label="服务对象背景" style="width:100%">
            <span>（基本生活情况、家庭人际关系、社交情况、社会支持网络及重要经历等）</span>
            <Input :maxlength="400" type="textarea" show-word-limit v-model="archivesData.archiveAssessNeedVo.userBg" :rows="4" style="margin: 0 24px;width: calc(100% - 48px)"></Input>
        </FormItem>
        <FormItem label="服务对象问题/需求" style="width:100%">
            <span>（服务对象认为需要处理的问题/转介方表示需要社工帮助处理的问题）（按优先次序）</span>
            <Input :maxlength="400" type="textarea" show-word-limit v-model="archivesData.archiveAssessNeedVo.userNeed" :rows="4" style="margin: 0 24px;width: calc(100% - 48px)"></Input>
        </FormItem>
        <FormItem label="服务计划" style="width:100%">
            <span>（列明服务目标、服务内容、服务时间/频率、志愿者参与、链接社会资源等内容）</span>
            <Input :maxlength="400" type="textarea" show-word-limit v-model="archivesData.archiveAssessNeedVo.servicePlan" :rows="4" style="margin: 0 24px;width: calc(100% - 48px)"></Input>
        </FormItem>
    </Form>
    <LiefengModal
        title="案主问题及需求"
        :value="seeDemandStatus"
        @input="seeDemandStatusFn"
        width="90%"
        height="calc(100vh - 100px)"
    >
        <template v-slot:contentarea>
            <SeeDemand :questionList="archivesData.archiveQuestionConfigVos"></SeeDemand>
        </template>
    </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from '@/components/LiefengModal'
import SeeDemand from '@/views/archives/childrens/changeArchivesChildrens/seeDemand'
export default {
  props: ["archivesData"],
  data() {
    return {
        seeDemandStatus: false,
    };
  },
  methods: {
      seeDemandStatusFn(status) {
          this.seeDemandStatus = status;
      }
  },
  components: {
      LiefengModal,SeeDemand
  }
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-input[disabled], fieldset[disabled] .ivu-input,/deep/.ivu-select-disabled .ivu-select-selection,/deep/.ivu-radio-disabled .ivu-radio-inner {
        background-color: #fff!important;
        color: #515a6e;
    }
</style>